import { fetchWithToken } from 'utils/fetch';
import { checkSuccess } from 'utils/requests';
import keysToCamel from 'utils/keysToCamel';

export async function getTemplates(): Promise<FormTemplate[]> {
  const response = await fetchWithToken('/templates');
  const data = await response.json();

  checkSuccess(data);

  return keysToCamel(data);
}
